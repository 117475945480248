import React, { useEffect, useState } from 'react'
import { mainTheme } from '../MaterialUiThemes/themes'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useLocation } from '@reach/router'
import { useSessionIfExists } from '../hooks/useAuth'
import LogRocket from 'logrocket'
import { httpGet } from '../services/http'
import './style.less'
import { getItem, setItem, tokenPaths } from '../utils/localStorage'
import * as dayjs from 'dayjs'

const blackList = {
    emails: (email: string | undefined) => email?.toLowerCase()?.endsWith('@ikenson.com'),
    ip: (ip: string | undefined) => ip === '93.164.23.96' || ip === '80.62.117.19',
    occassionalOldUser: (signed_up_at) => {
        if (signed_up_at != null) return true
        if (dayjs(signed_up_at).isAfter(dayjs().subtract(4, 'months'))) return true
        if (Math.random() > 0.8) return false
    },
}

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export default function PageWrapper({ children, theme = mainTheme }) {
    const [cookieValue, setCookieValue] = React.useState<number>(new Date().valueOf())
    const { session } = useSessionIfExists()
    const { pathname, search } = useLocation()

    React.useEffect(() => {
        const mixpanelId = '0db66d54d3b9e4d235c91770d7ab9f3a'
        mixpanel.init(mixpanelId)
    }, [])

    React.useEffect(() => {
        const searchParameters = new URLSearchParams(search)
        const bareFeatures = searchParameters.get('use_bare')
        if (bareFeatures === 'true') {
            setItem(tokenPaths.useBareFeatures, 'true')
        }
    }, [search])

    React.useEffect(() => {
        if (!pathname) return
        mixpanel.track(`page_view-${pathname}`, { path_name: pathname })
    }, [pathname])

    React.useEffect(() => {
        if (!session?.isLoggedIn) return
        mixpanel.identify(session.user?.user_id)
        mixpanel.people.set({
            email: session?.user?.email,
            $email: session?.user?.email,
            name: session?.user?.name,
            is_mail_verified: session?.user?.is_mail_verified,
            signed_up_at: session?.user?.signed_up_at,
            has_paid: session?.user?.has_paid,
            current_period_end: session?.user?.current_period_end,
            feature_flags: session?.user?.feature_flags,
        })
    }, [session])

    async function startLogger() {
        const result = await httpGet<{ ip_address: string }>('/auth/ip')
        const ipAddress = result?.data?.ip_address
        let lastLogCalculation = getItem(tokenPaths.lastLogCalculation)
        let createNewCalculation = true
        if (lastLogCalculation) {
            const data = JSON.parse(lastLogCalculation)
            if (dayjs(data.date).isAfter(dayjs().subtract(24, 'hours'))) {
                createNewCalculation = false
                if (!data.result) return
            }
        }
        const newLastLogCalculation = { date: dayjs().toDate(), result: true }
        if (createNewCalculation) {
            if (
                blackList.emails(session?.user?.email) ||
                blackList.ip(ipAddress) ||
                !blackList.occassionalOldUser(session?.user?.signed_up_at)
            ) {
                newLastLogCalculation.result = false
            }
            setItem(tokenPaths.lastLogCalculation, JSON.stringify(newLastLogCalculation))
            lastLogCalculation = newLastLogCalculation
        }
        if (lastLogCalculation?.result) {
            if (process.env.NODE_ENV === 'production') {
                LogRocket.init('s52rwr/ll33adk')
            }
        }
    }

    React.useEffect(() => {
        startLogger()
    }, [session])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {/* <CookieModal checkCookies={cookieValue} /> */}
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
