import {
    getStoredAuthToken,
    getStoredAuthTokenAdmin,
    getStoredAuthTokenRevisor,
} from '../utils/auth-token'

export const API_URL = process.env.GATSBY_API_URL
// export const API_URL = 'https://crappie-organic-blatantly.ngrok-free.app'

interface HttpResponse<T> extends Response {
    data?: T
}

class HTTPError extends Error {
    status: number | undefined

    info: any

    constructor(message?: string) {
        super(message)

        Object.setPrototypeOf(this, HTTPError.prototype)
    }
}

export const getAuthHeader = () => {
    const auth = getStoredAuthToken()
    if (!auth) return null
    return {
        Authorization: `Bearer ${auth}`,
        'X-App-Version': process.env.REACT_APP_VERSION,
    }
}

export const getCommonHeaders = () => {
    const commonHeaders = {}
    if (process.env.GATSBY_APP_ENV?.toLowerCase() === 'development') {
        commonHeaders['ngrok-skip-browser-warning'] = '1'
    }
    return commonHeaders
}

export const getAuthHeaderRevisor = () => {
    const auth = getStoredAuthTokenRevisor()
    if (!auth) return null
    return {
        Authorization: `Bearer ${auth}`,
        'X-App-Version': process.env.REACT_APP_VERSION,
    }
}

export const getAuthHeaderAdmin = () => {
    const auth = getStoredAuthTokenAdmin()
    if (!auth) return null
    return {
        Authorization: `Bearer ${auth}`,
        'X-App-Version': process.env.REACT_APP_VERSION,
    }
}

const http = <T>(request: RequestInfo): Promise<HttpResponse<T>> =>
    new Promise(async (resolve, reject) => {
        try {
            const response: HttpResponse<T> = await fetch(request)
            const contentType = response.headers.get('content-type')

            if (contentType && contentType.indexOf('application/json') !== -1) {
                response.data = await response.json()
            }

            if (response.ok) {
                resolve(response)
            } else {
                reject(response)
            }
        } catch (error) {
            reject(error)
        }
    })

export const httpGetAdmin = async <T>(
    path: string,
    args: RequestInit = {
        method: 'GET',
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderAdmin(),
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPostAdmin = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderAdmin(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPutAdmin = async <T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderAdmin(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpDeleteAdmin = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'DELETE',
        body: JSON.stringify(body || {}),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderAdmin(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpGetRevisor = async <T>(
    path: string,
    args: RequestInit = {
        method: 'GET',
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderRevisor(),
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPostRevisor = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderRevisor(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPutRevisor = async <T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderRevisor(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpDeleteRevisor = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'DELETE',
        body: JSON.stringify(body || {}),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderRevisor(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpGet = async <T>(
    path: string,
    args: RequestInit = {
        method: 'GET',
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeader(),
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPost = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            ...getAuthHeader(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPatch = async <T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeader(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpDelete = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'DELETE',
        body: JSON.stringify(body || {}),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeader(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const httpPut = async <T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(body || {}),
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeader(),
            'Content-Type': 'application/json',
        },
    }
): Promise<HttpResponse<T>> => http<T>(new Request(`${API_URL}${path}`, args))

export const fetcher = async <Data = unknown>(
    path: string,
    args: RequestInit = {
        method: 'GET',
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeader(),
        },
    }
): Promise<Data> => {
    const req = new Request(`${API_URL}${path}`, args)
    const res = await fetch(req)

    if (res.ok) {
        return res.json()
    }

    const error = new HTTPError('An error occurred while fetching the data.')
    error.info = await res.json()
    error.status = res.status

    throw error
}

export const useCacheFetcher = (updateData, showErrorMessage = () => {}) => {
    const fetcher = async <Data = unknown>(
        path: string,
        cacheReturn = true,
        args: RequestInit = {
            method: 'GET',
            headers: {
                ...getCommonHeaders(),
                ...getAuthHeader(),
            },
        }
    ): Promise<Data> => {
        var networkDataReceived = false
        const req = new Request(`${API_URL}${path}`, args)
        // fetch fresh data
        var networkUpdate = fetch(req)
            .then(function (response) {
                return response.json()
            })
            .then(function (data) {
                if (data) {
                    networkDataReceived = true
                    //console.log("Updating data from network!", path)
                    updateData(data)
                    return data
                }
                return null
            })
            .catch((error) => {
                //console.log(error);
                return null
            })
        //console.log("network update", networkUpdate);

        // fetch cached data
        let cacheResponse = null
        try {
            cacheResponse = caches
                .match(req)
                .then(function (response) {
                    if (!response) throw Error('No data')
                    return response.json()
                })
                .then(function (data) {
                    // don't overwrite newer network data
                    if (!networkDataReceived) {
                        //console.log("Updating data from cache!", path)
                        updateData(data)
                        return data
                    }
                    return null
                })
                .catch(function () {
                    // we didn't get cached data, the network is our last hope:
                    return null
                })
                .catch(showErrorMessage)
        } catch (ex) {
            console.log(ex)
        }

        const error = new HTTPError('An error occurred while fetching the data.')
        //console.log("res before ok check", networkUpdate, cacheResponse);
        if (cacheReturn && cacheResponse !== null) {
            const toReturn = await cacheResponse
            //console.log("final cache data", toReturn)
            return toReturn
        } else if (networkUpdate !== null) {
            const res = await networkUpdate
            //console.log("awaited network:", res);
            return res
        }
        //console.log("raising error")

        throw error
    }
    return fetcher
}

export const fetcherRevisor = async <Data = unknown>(
    path: string,
    args: RequestInit = {
        method: 'GET',
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderRevisor(),
        },
    }
): Promise<Data> => {
    const req = new Request(`${API_URL}${path}`, args)
    const res = await fetch(req)

    if (res.ok) {
        return res.json()
    }

    const error = new HTTPError('An error occurred while fetching the data.')
    error.info = await res.json()
    error.status = res.status

    throw error
}

export const fetcherAdmin = async <Data = unknown>(
    path: string,
    args: RequestInit = {
        method: 'GET',
        headers: {
            ...getCommonHeaders(),
            ...getAuthHeaderAdmin(),
        },
    }
): Promise<Data> => {
    const req = new Request(`${API_URL}${path}`, args)
    const res = await fetch(req)

    if (res.ok) {
        return res.json()
    }

    const error = new HTTPError('An error occurred while fetching the data.')
    error.info = await res.json()
    error.status = res.status

    throw error
}
