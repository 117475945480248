import { createTheme, DeprecatedThemeOptions, adaptV4Theme } from '@mui/material/styles'

export const LaunchButtonBorderRadius = 5
export const InputBoxBorderRadius = 20

declare module '@mui/material/styles' {
    interface Palette {
        success_color: Palette['primary']
        error: Palette['primary']
        PETS: Palette['primary']
        CHARITY: Palette['primary']
        FOOD: Palette['primary']
        TRANSPORT: Palette['primary']
        HOUSING: Palette['primary']
        LOAN: Palette['primary']
        PERSONAL: Palette['primary']
        SHOPPING: Palette['primary']
        INSURANCE: Palette['primary']
        INVEST: Palette['primary']
        KIDS: Palette['primary']
        VACATION: Palette['primary']
        OTHER: Palette['primary']
        ENTERTAINMENT: Palette['primary']
    }
    interface PaletteOptions {
        success_color: PaletteOptions['primary']
        error: PaletteOptions['primary']
        PETS: PaletteOptions['primary']
        CHARITY: PaletteOptions['primary']
        FOOD: PaletteOptions['primary']
        TRANSPORT: PaletteOptions['primary']
        HOUSING: PaletteOptions['primary']
        LOAN: PaletteOptions['primary']
        PERSONAL: PaletteOptions['primary']
        SHOPPING: PaletteOptions['primary']
        INSURANCE: PaletteOptions['primary']
        INVEST: PaletteOptions['primary']
        KIDS: PaletteOptions['primary']
        VACATION: PaletteOptions['primary']
        OTHER: PaletteOptions['primary']
        ENTERTAINMENT: PaletteOptions['primary']
    }
}

export const mainThemeOptions: DeprecatedThemeOptions = {
    spacing: 8,
    palette: {
        primary: {
            main: '#14143C',
            contrastText: '#fff',
            // light: '#E7F6DD',
            // dark: '#50795D',
        },
        secondary: {
            main: '#f5222d',
            contrastText: '#fff',
            // light: '#E7F6DD',
            // dark: '#50795D',
        },
        info: {
            main: '#e0e0e0',
            // contrastText: '#fff',
            // light: '#E7F6DD',
            // dark: '#50795D',
        },
        background: {
            default: '#FAFAFA',
        },
        success_color: {
            main: '#7EAC55',
            contrastText: '#fff',
        },
        error: {
            main: '#f5222d',
            contrastText: '#fff',
        },
        PETS: {
            main: 'rgba(165,42,42,1)',
        },
        CHARITY: {
            main: 'rgba(0,15,5,1)',
        },
        FOOD: {
            main: 'rgba(128, 0, 128,1)',
        },
        TRANSPORT: {
            main: 'rgba(0, 128, 0, 1)',
        },
        HOUSING: {
            main: 'rgba(0, 0, 255, 1)',
        },
        LOAN: {
            main: '#FF9090',
        },
        PERSONAL: {
            main: '#69B8A5',
        },
        SHOPPING: {
            main: 'rgba(177, 80, 211, 1)',
        },
        INSURANCE: {
            main: 'rgba(219, 148, 12, 1)',
        },
        INVEST: {
            main: 'rgba(67, 174, 29, 1)',
        },
        KIDS: {
            main: 'rgba(49, 198, 218, 1)',
        },
        VACATION: {
            main: 'rgba(93, 49, 218, 1)',
        },
        OTHER: {
            main: 'rgba(174,174,174,1)',
        },
        ENTERTAINMENT: {
            main: 'rgba(47,151,11, 1)',
        },
    },
    components: {
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'receipt-seperator' },
                    style: {
                        borderWidth: 1,
                        borderColor: 'black',
                    },
                },
            ],
        },
    },
}

export const mainTheme = createTheme(adaptV4Theme(mainThemeOptions))
